//
//
//
//
//
//
//
//
//

export default {
    name: 'MobileDropdownBgOverlay',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        toggleIsOpen: {
            type: Function,
            required: true,
        },
    },
};
