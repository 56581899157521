import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _016f484e = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _46ded805 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _fce02030 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _6d453664 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _89c15a4e = () => interopDefault(import('../pages/days-off.vue' /* webpackChunkName: "pages/days-off" */))
const _2da8d726 = () => interopDefault(import('../pages/days-off-calculator.vue' /* webpackChunkName: "pages/days-off-calculator" */))
const _0b8281a2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f49dd956 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _35e1d321 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _55abb68e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1d2b7aea = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _a7a21c12 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _53d7a5c6 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _0d98d077 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _04e91178 = () => interopDefault(import('../pages/company/administrators.vue' /* webpackChunkName: "pages/company/administrators" */))
const _14f6767a = () => interopDefault(import('../pages/company/employees.vue' /* webpackChunkName: "pages/company/employees" */))
const _40d2e0e6 = () => interopDefault(import('../pages/company/export.vue' /* webpackChunkName: "pages/company/export" */))
const _fc50d1f4 = () => interopDefault(import('../pages/company/groups.vue' /* webpackChunkName: "pages/company/groups" */))
const _6c3249a5 = () => interopDefault(import('../pages/company/non-working-days.vue' /* webpackChunkName: "pages/company/non-working-days" */))
const _2cfc0956 = () => interopDefault(import('../pages/company/settings.vue' /* webpackChunkName: "pages/company/settings" */))
const _e5cef61e = () => interopDefault(import('../pages/register/company.vue' /* webpackChunkName: "pages/register/company" */))
const _90899d4a = () => interopDefault(import('../pages/register/personal-information.vue' /* webpackChunkName: "pages/register/personal-information" */))
const _3da41f99 = () => interopDefault(import('../pages/register/team.vue' /* webpackChunkName: "pages/register/team" */))
const _60c3e3d7 = () => interopDefault(import('../pages/user/account-settings.vue' /* webpackChunkName: "pages/user/account-settings" */))
const _4969032c = () => interopDefault(import('../pages/user/approvers.vue' /* webpackChunkName: "pages/user/approvers" */))
const _19d367e6 = () => interopDefault(import('../pages/user/days-off.vue' /* webpackChunkName: "pages/user/days-off" */))
const _7fd2dcbe = () => interopDefault(import('../pages/user/employee-information.vue' /* webpackChunkName: "pages/user/employee-information" */))
const _53690a48 = () => interopDefault(import('../pages/user/export.vue' /* webpackChunkName: "pages/user/export" */))
const _131abe3e = () => interopDefault(import('../pages/user/password-change.vue' /* webpackChunkName: "pages/user/password-change" */))
const _183f259a = () => interopDefault(import('../pages/user/personal-information.vue' /* webpackChunkName: "pages/user/personal-information" */))
const _417d1354 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _db40ca54 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _68d041e8 = () => interopDefault(import('../pages/user/_id/account-settings.vue' /* webpackChunkName: "pages/user/_id/account-settings" */))
const _c27af656 = () => interopDefault(import('../pages/user/_id/approvers.vue' /* webpackChunkName: "pages/user/_id/approvers" */))
const _64bf4c42 = () => interopDefault(import('../pages/user/_id/days-off.vue' /* webpackChunkName: "pages/user/_id/days-off" */))
const _5d5fea56 = () => interopDefault(import('../pages/user/_id/employee-information.vue' /* webpackChunkName: "pages/user/_id/employee-information" */))
const _684e93bd = () => interopDefault(import('../pages/user/_id/export.vue' /* webpackChunkName: "pages/user/_id/export" */))
const _ba1fef2e = () => interopDefault(import('../pages/user/_id/password-change.vue' /* webpackChunkName: "pages/user/_id/password-change" */))
const _ddac7430 = () => interopDefault(import('../pages/user/_id/personal-information.vue' /* webpackChunkName: "pages/user/_id/personal-information" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-invitation",
    component: _016f484e,
    name: "accept-invitation___hu___default"
  }, {
    path: "/blog",
    component: _46ded805,
    name: "blog___hu___default"
  }, {
    path: "/change-password",
    component: _fce02030,
    name: "change-password___hu___default"
  }, {
    path: "/dashboard",
    component: _6d453664,
    name: "dashboard___hu___default"
  }, {
    path: "/days-off",
    component: _89c15a4e,
    name: "days-off___hu___default"
  }, {
    path: "/days-off-calculator",
    component: _2da8d726,
    name: "days-off-calculator___hu___default"
  }, {
    path: "/en",
    component: _0b8281a2,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _f49dd956,
    name: "forgot-password___hu___default"
  }, {
    path: "/help",
    component: _35e1d321,
    name: "help___hu___default"
  }, {
    path: "/hu",
    component: _0b8281a2,
    name: "index___hu"
  }, {
    path: "/login",
    component: _55abb68e,
    name: "login___hu___default"
  }, {
    path: "/logout",
    component: _1d2b7aea,
    name: "logout___hu___default"
  }, {
    path: "/privacy-policy",
    component: _a7a21c12,
    name: "privacy-policy___hu___default"
  }, {
    path: "/register",
    component: _53d7a5c6,
    name: "register___hu___default"
  }, {
    path: "/terms-of-use",
    component: _0d98d077,
    name: "terms-of-use___hu___default"
  }, {
    path: "/company/administrators",
    component: _04e91178,
    name: "company-administrators___hu___default"
  }, {
    path: "/company/employees",
    component: _14f6767a,
    name: "company-employees___hu___default"
  }, {
    path: "/company/export",
    component: _40d2e0e6,
    name: "company-export___hu___default"
  }, {
    path: "/company/groups",
    component: _fc50d1f4,
    name: "company-groups___hu___default"
  }, {
    path: "/company/non-working-days",
    component: _6c3249a5,
    name: "company-non-working-days___hu___default"
  }, {
    path: "/company/settings",
    component: _2cfc0956,
    name: "company-settings___hu___default"
  }, {
    path: "/en/accept-invitation",
    component: _016f484e,
    name: "accept-invitation___en"
  }, {
    path: "/en/blog",
    component: _46ded805,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _fce02030,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _6d453664,
    name: "dashboard___en"
  }, {
    path: "/en/days-off",
    component: _89c15a4e,
    name: "days-off___en"
  }, {
    path: "/en/days-off-calculator",
    component: _2da8d726,
    name: "days-off-calculator___en"
  }, {
    path: "/en/forgot-password",
    component: _f49dd956,
    name: "forgot-password___en"
  }, {
    path: "/en/help",
    component: _35e1d321,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _55abb68e,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _1d2b7aea,
    name: "logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _a7a21c12,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _53d7a5c6,
    name: "register___en"
  }, {
    path: "/en/terms-of-use",
    component: _0d98d077,
    name: "terms-of-use___en"
  }, {
    path: "/hu/accept-invitation",
    component: _016f484e,
    name: "accept-invitation___hu"
  }, {
    path: "/hu/blog",
    component: _46ded805,
    name: "blog___hu"
  }, {
    path: "/hu/change-password",
    component: _fce02030,
    name: "change-password___hu"
  }, {
    path: "/hu/dashboard",
    component: _6d453664,
    name: "dashboard___hu"
  }, {
    path: "/hu/days-off",
    component: _89c15a4e,
    name: "days-off___hu"
  }, {
    path: "/hu/days-off-calculator",
    component: _2da8d726,
    name: "days-off-calculator___hu"
  }, {
    path: "/hu/forgot-password",
    component: _f49dd956,
    name: "forgot-password___hu"
  }, {
    path: "/hu/help",
    component: _35e1d321,
    name: "help___hu"
  }, {
    path: "/hu/login",
    component: _55abb68e,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _1d2b7aea,
    name: "logout___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _a7a21c12,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/register",
    component: _53d7a5c6,
    name: "register___hu"
  }, {
    path: "/hu/terms-of-use",
    component: _0d98d077,
    name: "terms-of-use___hu"
  }, {
    path: "/register/company",
    component: _e5cef61e,
    name: "register-company___hu___default"
  }, {
    path: "/register/personal-information",
    component: _90899d4a,
    name: "register-personal-information___hu___default"
  }, {
    path: "/register/team",
    component: _3da41f99,
    name: "register-team___hu___default"
  }, {
    path: "/user/account-settings",
    component: _60c3e3d7,
    name: "user-account-settings___hu___default"
  }, {
    path: "/user/approvers",
    component: _4969032c,
    name: "user-approvers___hu___default"
  }, {
    path: "/user/days-off",
    component: _19d367e6,
    name: "user-days-off___hu___default"
  }, {
    path: "/user/employee-information",
    component: _7fd2dcbe,
    name: "user-employee-information___hu___default"
  }, {
    path: "/user/export",
    component: _53690a48,
    name: "user-export___hu___default"
  }, {
    path: "/user/password-change",
    component: _131abe3e,
    name: "user-password-change___hu___default"
  }, {
    path: "/user/personal-information",
    component: _183f259a,
    name: "user-personal-information___hu___default"
  }, {
    path: "/en/company/administrators",
    component: _04e91178,
    name: "company-administrators___en"
  }, {
    path: "/en/company/employees",
    component: _14f6767a,
    name: "company-employees___en"
  }, {
    path: "/en/company/export",
    component: _40d2e0e6,
    name: "company-export___en"
  }, {
    path: "/en/company/groups",
    component: _fc50d1f4,
    name: "company-groups___en"
  }, {
    path: "/en/company/non-working-days",
    component: _6c3249a5,
    name: "company-non-working-days___en"
  }, {
    path: "/en/company/settings",
    component: _2cfc0956,
    name: "company-settings___en"
  }, {
    path: "/en/register/company",
    component: _e5cef61e,
    name: "register-company___en"
  }, {
    path: "/en/register/personal-information",
    component: _90899d4a,
    name: "register-personal-information___en"
  }, {
    path: "/en/register/team",
    component: _3da41f99,
    name: "register-team___en"
  }, {
    path: "/en/user/account-settings",
    component: _60c3e3d7,
    name: "user-account-settings___en"
  }, {
    path: "/en/user/approvers",
    component: _4969032c,
    name: "user-approvers___en"
  }, {
    path: "/en/user/days-off",
    component: _19d367e6,
    name: "user-days-off___en"
  }, {
    path: "/en/user/employee-information",
    component: _7fd2dcbe,
    name: "user-employee-information___en"
  }, {
    path: "/en/user/export",
    component: _53690a48,
    name: "user-export___en"
  }, {
    path: "/en/user/password-change",
    component: _131abe3e,
    name: "user-password-change___en"
  }, {
    path: "/en/user/personal-information",
    component: _183f259a,
    name: "user-personal-information___en"
  }, {
    path: "/hu/company/administrators",
    component: _04e91178,
    name: "company-administrators___hu"
  }, {
    path: "/hu/company/employees",
    component: _14f6767a,
    name: "company-employees___hu"
  }, {
    path: "/hu/company/export",
    component: _40d2e0e6,
    name: "company-export___hu"
  }, {
    path: "/hu/company/groups",
    component: _fc50d1f4,
    name: "company-groups___hu"
  }, {
    path: "/hu/company/non-working-days",
    component: _6c3249a5,
    name: "company-non-working-days___hu"
  }, {
    path: "/hu/company/settings",
    component: _2cfc0956,
    name: "company-settings___hu"
  }, {
    path: "/hu/register/company",
    component: _e5cef61e,
    name: "register-company___hu"
  }, {
    path: "/hu/register/personal-information",
    component: _90899d4a,
    name: "register-personal-information___hu"
  }, {
    path: "/hu/register/team",
    component: _3da41f99,
    name: "register-team___hu"
  }, {
    path: "/hu/user/account-settings",
    component: _60c3e3d7,
    name: "user-account-settings___hu"
  }, {
    path: "/hu/user/approvers",
    component: _4969032c,
    name: "user-approvers___hu"
  }, {
    path: "/hu/user/days-off",
    component: _19d367e6,
    name: "user-days-off___hu"
  }, {
    path: "/hu/user/employee-information",
    component: _7fd2dcbe,
    name: "user-employee-information___hu"
  }, {
    path: "/hu/user/export",
    component: _53690a48,
    name: "user-export___hu"
  }, {
    path: "/hu/user/password-change",
    component: _131abe3e,
    name: "user-password-change___hu"
  }, {
    path: "/hu/user/personal-information",
    component: _183f259a,
    name: "user-personal-information___hu"
  }, {
    path: "/en/blog/:article",
    component: _417d1354,
    name: "blog-article___en"
  }, {
    path: "/en/user/:id?",
    component: _db40ca54,
    name: "user-id___en",
    children: [{
      path: "account-settings",
      component: _68d041e8,
      name: "user-id-account-settings___en"
    }, {
      path: "approvers",
      component: _c27af656,
      name: "user-id-approvers___en"
    }, {
      path: "days-off",
      component: _64bf4c42,
      name: "user-id-days-off___en"
    }, {
      path: "employee-information",
      component: _5d5fea56,
      name: "user-id-employee-information___en"
    }, {
      path: "export",
      component: _684e93bd,
      name: "user-id-export___en"
    }, {
      path: "password-change",
      component: _ba1fef2e,
      name: "user-id-password-change___en"
    }, {
      path: "personal-information",
      component: _ddac7430,
      name: "user-id-personal-information___en"
    }]
  }, {
    path: "/hu/blog/:article",
    component: _417d1354,
    name: "blog-article___hu"
  }, {
    path: "/hu/user/:id?",
    component: _db40ca54,
    name: "user-id___hu",
    children: [{
      path: "account-settings",
      component: _68d041e8,
      name: "user-id-account-settings___hu"
    }, {
      path: "approvers",
      component: _c27af656,
      name: "user-id-approvers___hu"
    }, {
      path: "days-off",
      component: _64bf4c42,
      name: "user-id-days-off___hu"
    }, {
      path: "employee-information",
      component: _5d5fea56,
      name: "user-id-employee-information___hu"
    }, {
      path: "export",
      component: _684e93bd,
      name: "user-id-export___hu"
    }, {
      path: "password-change",
      component: _ba1fef2e,
      name: "user-id-password-change___hu"
    }, {
      path: "personal-information",
      component: _ddac7430,
      name: "user-id-personal-information___hu"
    }]
  }, {
    path: "/blog/:article",
    component: _417d1354,
    name: "blog-article___hu___default"
  }, {
    path: "/user/:id?",
    component: _db40ca54,
    name: "user-id___hu___default",
    children: [{
      path: "account-settings",
      component: _68d041e8,
      name: "user-id-account-settings___hu___default"
    }, {
      path: "approvers",
      component: _c27af656,
      name: "user-id-approvers___hu___default"
    }, {
      path: "days-off",
      component: _64bf4c42,
      name: "user-id-days-off___hu___default"
    }, {
      path: "employee-information",
      component: _5d5fea56,
      name: "user-id-employee-information___hu___default"
    }, {
      path: "export",
      component: _684e93bd,
      name: "user-id-export___hu___default"
    }, {
      path: "password-change",
      component: _ba1fef2e,
      name: "user-id-password-change___hu___default"
    }, {
      path: "personal-information",
      component: _ddac7430,
      name: "user-id-personal-information___hu___default"
    }]
  }, {
    path: "/",
    component: _0b8281a2,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
