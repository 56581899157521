//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CustomDatepicker',
    props: {
        set: {
            type: Function,
            required: true,
        },
        value: {
            type: [String, Date],
            required: false,
            default: null,
        },
        inputCb: {
            type: Function,
            required: false,
            default: null,
        },
        onShow: {
            type: Function,
            required: false,
            default: () => {},
        },
        onHide: {
            type: Function,
            required: false,
            default: () => {},
        },
        onChange: {
            type: Function,
            required: false,
            default: () => {},
        },
        maxDate: {
            type: Date,
            required: false,
            default: null,
        },
        dateFormat: {
            type: String,
            required: false,
            default: 'yy-mm-dd',
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        manualInput: {
            type: Boolean,
            required: false,
            default: null,
        },
        view: {
            type: String,
            required: false,
            default: 'date',
        },
        specificDate: {
            type: Date,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableNonWorkingDays: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            calendarDropdownYearAndMonth: null,
        };
    },
    computed: {
        hasManualInput() {
            if (this.manualInput === null) {
                return !this.isTouchUI;
            }
            return this.manualInput;
        },
        disabledDates() {
            if (!this.disableNonWorkingDays) {
                return null;
            }

            const res = [];

            let startDateOfDisplayedMonthInCalendarDropdown;

            if (this.calendarDropdownYearAndMonth) {
                startDateOfDisplayedMonthInCalendarDropdown = `${this.calendarDropdownYearAndMonth.year}-${
                    this.calendarDropdownYearAndMonth.month + 1
                }-01`;
            } else {
                startDateOfDisplayedMonthInCalendarDropdown = this.$dayjs().format('YYYY-MM-DD');
            }

            const holidays = this.$store.state.company.holidays;

            holidays.forEach((holiday) => {
                res.push(this.$dayjs(holiday.date).toDate());
            });

            const nonWorkingDayIndexes = this.getNonWorkingDayIndexes();

            for (let i = 0; i < this.$dayjs(startDateOfDisplayedMonthInCalendarDropdown).daysInMonth(); i++) {
                const date = this.$dayjs(startDateOfDisplayedMonthInCalendarDropdown).date(i + 1);
                if (
                    nonWorkingDayIndexes.includes(date.day()) &&
                    !holidays.find((x) => x.observed_on === date.format('YYYY-MM-DD'))
                ) {
                    res.push(date.toDate());
                }
            }

            return res;
        },
    },
    methods: {
        getNonWorkingDayIndexes() {
            return this.$getWeekDays().reduce((acc, curr, i) => {
                if (this.$store.state.auth.user.selected_company[curr] === 0) {
                    if (i === 6) {
                        acc.push(0);
                    } else {
                        acc.push(i + 1);
                    }
                }
                return acc;
            }, []);
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.$refs.calendar.overlayVisible = false;
            }
        },
        handleShow() {
            if (this.specificDate && !this.value) {
                this.set(this.specificDate);
            }
            this.$emit('show');
        },
        handleInput(value) {
            const inputValue = this.$refs.calendar.$el.querySelector('input').value;

            if (typeof inputValue === 'string' && (!inputValue.match(/^[0-9-]*$/) || inputValue.length > 10)) {
                return;
            }
            if (this.$dayjs(inputValue, 'YYYY-MM-DD', true).isValid()) {
                this.set(value);
            } else {
                this.set(inputValue);
                if (this.inputCb) {
                    this.inputCb();
                }
            }
        },
        handleDateSelect(value) {
            this.set(value);
            if (this.inputCb) {
                this.inputCb();
            }
        },
        forceUpdateCalendar(e) {
            this.calendarDropdownYearAndMonth = e;
        },
    },
};
