export const state = () => ({
    host: null,
});

export const mutations = {
    setHost(state, payload) {
        state.host = payload;
    },
};

export const actions = {
    handleDayOffCRUD({ dispatch }) {
        dispatch('auth/fetchUser');
        dispatch('company/fetchUnapprovedDayOffRequests');
        dispatch('company/fetchDaysOff');
        dispatch('company/fetchAbsents');
    },
    async nuxtServerInit({ dispatch, commit, state }, { req }) {
        commit('setHost', req.headers.host);

        try {
            if (!state.auth.token) {
                return;
            }

            await dispatch('auth/fetchUser');

            if (state.auth.user) {
                const userPreferredLanguage = state.auth.user.preferences.language;

                if (userPreferredLanguage !== this.$i18n.locale) {
                    await this.$i18n.setLocale(userPreferredLanguage);
                }
            }
        } catch (err) {
            console.log(err);
        }
    },
};
