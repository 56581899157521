//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NavLink',
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        hash: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        localizedHref() {
            if (!this.to) {
                return this.localePath('/');
            }
            return this.to;
        },
    },
};
