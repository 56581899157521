//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'AvatarAndName',
    props: {
        isInteractive: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: [String, Number],
            required: false,
            default: null,
        },
        firstName: {
            type: [String, Number],
            required: true,
        },
        lastName: {
            type: [String, Number],
            required: true,
        },
        avatar: {
            type: String,
            required: true,
        },
        position: {
            type: String,
            required: false,
            default: null,
        },
        avatarSize: {
            type: String,
            required: false,
            default: null,
        },
        isNameHiddenOnMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
        textColor: {
            type: String,
            required: false,
            default: 'black',
        },
        isInactive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
