//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MultiSelect from 'primevue/multiselect';
import dayOffRequest from '../../mixins/dayOffRequest';
import ExactPeriodDatepicker from '../UI/Inputs/ExactPeriodDatepicker';
import AvatarAndName from '../Generic/AvatarAndName';
import MobileDropdownBgOverlay from '../Generic/MobileDropdownBgOverlay';
import BaseModal from './BaseModal';

export default {
    name: 'DayOffRequestModal',
    components: {
        BaseModal,
        ExactPeriodDatepicker,
        AvatarAndName,
        MultiSelect,
        MobileDropdownBgOverlay,
    },
    mixins: [dayOffRequest],
    props: {
        // stuff for every type of modals
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },

        // create modal only (only if modal was opened by empty calendar cell click)
        requestUnderCreation: {
            type: Object,
            required: false,
            default: null,
        },

        setIsDayOffCreationPending: {
            type: Function,
            required: false,
            default: null,
        },

        // edit modal only
        currentlyEditedRequest: {
            type: Object,
            required: false,
            default: null,
        },

        openDenyDayOffModal: {
            type: Function,
            required: false,
            default: null,
        },

        // deny modal only
        requestUnderDenial: {
            type: Object,
            required: false,
            default: null,
        },

        // cancel modal only
        requestUnderCancellation: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            dayOffStart: null,
            dayOffEnd: null,
            requestMessage: '',
            denialMessage: '',
            cancellationMessage: '',
            dayOffType: null,
            dayOffTypes: [],
            selectedEmployees: [],
            isNotDeletable: true,
            autoApprove: false,
            isUserMultiselectOpen: false,
        };
    },
    computed: {
        employees() {
            return this.$store.state.company.employeesEssential.reduce((acc, curr) => {
                if (curr.is_active) {
                    acc.push({
                        id: curr.id,
                        approver_ids: curr.approver_ids,
                        name: this.getLocalizedUserName(curr.first_name, curr.last_name),
                        avatar: curr.avatar,
                    });
                }
                return acc;
            }, []);
        },
        dayOffUnderDenialDuration() {
            return this.$getDurationInDays(this.requestUnderDenial.from_date, this.requestUnderDenial.to_date);
        },
        dayOffUnderCancellationDuration() {
            return this.$getDurationInDays(
                this.requestUnderCancellation.from_date,
                this.requestUnderCancellation.to_date
            );
        },
    },
    watch: {
        isOpen() {
            // on modal open, pre-fill the form fields if needed
            //
            if (this.isOpen) {
                let userID;

                if (this.type === 'create') {
                    if (this.requestUnderCreation) {
                        userID = this.requestUnderCreation.user_id;
                        this.dayOffStart = this.$dayjs(this.requestUnderCreation.from_date).toDate();
                        this.dayOffEnd = this.$dayjs(this.requestUnderCreation.to_date).toDate();

                        this.selectedEmployees.push(this.employees.find((user) => user.id === +userID));
                    } else {
                        this.dayOffStart = null;
                        this.dayOffEnd = null;
                    }
                } else if (this.type === 'edit') {
                    userID = this.currentlyEditedRequest.submitter?.id;

                    this.selectedEmployees.push(this.employees.find((user) => user.id === +userID));
                    const dayOffTypeId = this.currentlyEditedRequest.type.id;
                    this.dayOffType = {
                        id: dayOffTypeId,
                        key: `${this.id}-${dayOffTypeId}`,
                        label: this.getDayOffTypeDetails(dayOffTypeId).label,
                    };
                    this.requestMessage = this.currentlyEditedRequest.comment;
                    this.dayOffStart = this.$dayjs(this.currentlyEditedRequest.from_date).toDate();
                    this.dayOffEnd = this.$dayjs(this.currentlyEditedRequest.to_date).toDate();
                    this.autoApprove = !!this.currentlyEditedRequest.autoApprove;
                }

                this.dayOffTypes = this.getDayOffTypes(this.id, userID);

                if (this.type === 'create') {
                    this.dayOffType = this.dayOffTypes.find((x) => x.id === 1);
                }
            } else {
                this.resetForm();
            }
        },
    },
    methods: {
        setDayOffStart(val) {
            this.dayOffStart = val;
        },
        setDayOffEnd(val) {
            this.dayOffEnd = val;
        },
        createOrModifyDayOffRequest() {
            let userId;
            let request;
            let path;

            if (this.type === 'create') {
                if (this.selectedEmployees.length && this.isAdmin) {
                    userId = this.selectedEmployees.map((x) => x.id);
                } else if (this.requestUnderCreation) {
                    userId = this.requestUnderCreation.user_id;
                }
                request = this.$axios.$post;
                path = '/day-off';
            } else if (this.type === 'edit') {
                userId = this.currentlyEditedRequest.submitter.id;
                request = this.$axios.$put;
                path = '/day-off/' + this.currentlyEditedRequest.id;
            }

            const payload = {
                type_id: this.dayOffType ? this.dayOffType.id : null,
                from_date: this.$getISODate(this.dayOffStart),
                to_date: this.$getISODate(this.dayOffEnd),
                auto_approve: this.autoApprove ? 1 : 0,
                comment: this.requestMessage,
            };

            payload.user_id = userId;

            if (this.isAdmin) {
                payload.deletable = !this.isNotDeletable ? 1 : 0;
            }

            request(path, payload)
                .then((res) => {
                    if (res.success === 1) {
                        if (this.type === 'create' && this.setIsDayOffCreationPending) {
                            this.setIsDayOffCreationPending(true);
                        }

                        this.close();

                        this.$toast.add({
                            summary: {
                                icon: require('~/assets/images/icons/settings.svg'),
                                message: this.$t(
                                    `day_off.day_off_request_${this.type === 'create' ? 'created' : 'modified'}`
                                ),
                            },
                            life: 4000,
                        });

                        this.updateStore();
                    } else {
                        this.$refs.form.setErrors(res.error);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 404) {
                        this.showDayOffNotFoundErrorToastMessage();
                    }
                    this.close();
                });
        },
        denyDayOffRequest(request) {
            this.$axios
                .$get('/day-off/reject/' + request.id)
                .then((res) => {
                    if (res.success === 1) {
                        this.close();

                        this.$toast.add({
                            summary: {
                                icon: require('~/assets/images/icons/denied.svg'),
                                message: this.$t('day_off.request_denied'),
                            },
                            life: 4000,
                        });

                        this.updateStore();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 404) {
                        this.showDayOffNotFoundErrorToastMessage();
                    }
                    this.close();
                });
        },

        cancelDayOffRequest() {
            let requestID;

            if (this.requestUnderCancellation) {
                requestID = this.requestUnderCancellation.id;
            } else {
                requestID = this.currentlyEditedRequest.id;
            }

            this.$axios
                .$delete('/day-off/' + requestID)
                .then((res) => {
                    if (res.success === 1) {
                        this.close();

                        this.$toast.add({
                            summary: {
                                icon: require('~/assets/images/icons/settings.svg'),
                                message: this.$t('day_off.request_canceled'),
                            },
                            life: 4000,
                        });
                        this.updateStore();
                    }
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        this.showDayOffNotFoundErrorToastMessage();
                    }
                    this.close();
                    console.log(err);
                });
        },
        updateStore() {
            this.$store.dispatch('auth/fetchUser');
            this.$store.dispatch('company/fetchUnapprovedDayOffRequests');
            this.$store.dispatch('company/fetchDaysOff');
            this.$store.dispatch('company/fetchAbsents');
        },
        toggleIsUserMultiselectOpen() {
            this.isUserMultiselectOpen = !this.isUserMultiselectOpen;
        },
        onUserMultiselectShow() {
            this.isUserMultiselectOpen = true;
            this.$disableBodyScroll(document.querySelector('.p-multiselect-items-wrapper'));
        },
        onUserMultiselectHide() {
            this.isUserMultiselectOpen = false;
            this.$enableBodyScroll(document.querySelector('.p-multiselect-items-wrapper'));
        },
        resetForm() {
            this.dayOffStart = null;
            this.dayOffEnd = null;
            this.dayOffType = this.dayOffTypes.find((x) => x.id === 1);
            this.selectedEmployees = [];
            this.isNotDeletable = true;
            this.autoApprove = false;
            this.requestMessage = '';
            this.denialMessage = '';
        },
    },
};
