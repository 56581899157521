var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center relative",class:[
        _vm.target === 'new-request-modal' ? 'new-request-period' : '',
        _vm.isFromCalendarOpen || _vm.isToCalendarOpen ? 'z-40' : 'z-1' ]},[_c('div',{staticClass:"p-field p-input-icon-right period-start-container",class:{
            disabled: _vm.disabled,
            'z-10': _vm.isFromCalendarOpen,
        }},[_c('i',{staticClass:"bx bx-calendar-week text-green-400 pointer-events-none"}),_vm._v(" "),_c('label',{staticClass:"input-label capitalize required",attrs:{"for":"payroll-export_exact-period-from"}},[_c('T',{attrs:{"t":"generic.from"}})],1),_vm._v(" "),_c('CustomDatepicker',{class:{ 'position-unset': _vm.$device.isMobileOrTablet },attrs:{"id":"payroll-export_exact-period-from","name":"payroll-export_exact-period-from","value":_vm.from,"set":_vm.setPeriodFromAndPeriodToIfEmpty,"input-cb":_vm.handleDateFromInput,"disable-non-working-days":_vm.disableNonWorkingDays},on:{"show":function($event){_vm.isFromCalendarOpen = true},"hide":function($event){_vm.isFromCalendarOpen = false}}})],1),_vm._v(" "),_c('span',{staticClass:"px-2 dash"},[_vm._v(" "+_vm._s(_vm.target !== 'new-request-modal' ? '-' : '')+" ")]),_vm._v(" "),_c('div',{staticClass:"p-field p-input-icon-right position-unset",class:{
            disabled: _vm.disabled,
            'z-10': _vm.isToCalendarOpen,
        }},[_c('i',{staticClass:"bx bx-calendar-week text-green-400 pointer-events-none"}),_vm._v(" "),_c('label',{staticClass:"input-label capitalize to-label required",attrs:{"for":"payroll-export_exact-period-to"}},[_c('T',{attrs:{"t":"generic.to"}})],1),_vm._v(" "),_c('CustomDatepicker',{class:{ 'position-unset': _vm.$device.isMobileOrTablet },attrs:{"id":"payroll-export_exact-period-to","name":"payroll-export_exact-period-to","value":_vm.to,"set":_vm.setPeriodTo,"input-cb":_vm.handleDateToInput,"disable-non-working-days":_vm.disableNonWorkingDays},on:{"show":function($event){_vm.isToCalendarOpen = true},"hide":function($event){_vm.isToCalendarOpen = false}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }