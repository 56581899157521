//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomDatepicker from './CustomDatepicker';

export default {
    name: 'ExactPeriodDatepicker',
    components: {
        CustomDatepicker,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        from: {
            type: [Date, String],
            required: false,
            default: null,
        },
        to: {
            type: [Date, String],
            required: false,
            default: null,
        },
        setPeriodFrom: {
            type: Function,
            required: true,
        },
        setPeriodTo: {
            type: Function,
            required: true,
        },
        target: {
            type: String,
            required: false,
            default: null,
        },
        disableNonWorkingDays: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isFromCalendarOpen: false,
            isToCalendarOpen: false,
        };
    },
    methods: {
        setPeriodFromAndPeriodToIfEmpty(val) {
            this.setPeriodFrom(val);
            if (!this.to && typeof val === 'object' && this.target === 'new-request-modal') {
                this.setPeriodTo(val);
            }
        },
        handleDateFromInput() {
            this.$nextTick(() => {
                if (
                    typeof this.from === 'object' &&
                    typeof this.to === 'object' &&
                    this.$dayjs(this.to).isBefore(this.$dayjs(this.from), 'day')
                ) {
                    this.setPeriodTo('');
                }
            });
        },
        handleDateToInput() {
            this.$nextTick(() => {
                if (
                    typeof this.from === 'object' &&
                    typeof this.to === 'object' &&
                    this.$dayjs(this.to).isBefore(this.$dayjs(this.from), 'day')
                ) {
                    this.setPeriodFrom('');
                }
            });
        },
    },
};
