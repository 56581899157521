export default ({ app, $config, route, req, i18n }) => {
    // Find out host url from x-forwarded-host header (must be configured in the proxy server properly)
    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'];
    } else {
        host = window.location.origin;
    }

    if ($config.nodeENV !== 'development' || !/^https/.test(host)) {
        host = 'https://' + host;
    }

    // Add scripts
    const script = [];

    if ($config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host)) {
        script.push({
            src: '/scripts/quantcast.js',
            async: true,
        });

        script.push({
            src: '/scripts/meta-pixel.js',
            async: true,
        });
    }

    // Add meta tags
    const meta = [];

    meta.push({
        hid: 'og-image',
        property: 'og:image',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        hid: 'og-image-url',
        property: 'og:image:url',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        property: 'og:url',
        content: host + route.fullPath,
    });

    meta.push({
        property: 'og:site_name',
        hid: 'og-site-name',
        content: i18n.t('generic.meta.title'),
    });

    meta.push({
        property: 'title',
        hid: 'title',
        content: i18n.t('generic.meta.title'),
    });

    meta.push({
        property: 'og:title',
        hid: 'og-title',
        content: i18n.t('generic.meta.title'),
    });

    meta.push({
        property: 'description',
        hid: 'description',
        content: i18n.t('generic.meta.description'),
    });

    meta.push({
        property: 'og:description',
        hid: 'og-description',
        content: i18n.t('generic.meta.description'),
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    app.head.meta.push(...meta);
    app.head.script.push(...script);
};
