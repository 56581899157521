//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        mobileOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        // some modals have a wider container on desktop (e.g. the payroll export modal)
        isLarge: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            keyPressHandler: this.handleKeyPress,
            arePointerEventsDisabledOnBgOverlay: true,
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                setTimeout(() => {
                    this.arePointerEventsDisabledOnBgOverlay = false;
                    this.$disableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
                }, 250);
            } else {
                this.$enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', this.keyPressHandler);
    },
    beforeDestroy() {
        if (this.$refs.modalWrapper.querySelector('.modal-content')) {
            this.$enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
        }
    },
    destroyed() {
        window.removeEventListener('keyup', this.keyPressHandler);
    },
    methods: {
        closeModal() {
            this.$enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
            this.arePointerEventsDisabledOnBgOverlay = true;
            this.close();
        },
        handleKeyPress(e) {
            if (this.isOpen && e.key === 'Escape') {
                this.$enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
                this.arePointerEventsDisabledOnBgOverlay = true;
                this.close();
            }
        },
    },
};
